@import url("https://fonts.googleapis.com/css2?family=Jaldi&display=swap");

.gallery-links-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    flex-wrap: wrap;
    padding-top: 30px;
    padding-bottom: 50px;
}

h2 {
    font-size: 20px;
    color: white;
    text-align: center;
    margin-top: 3px;
    margin-bottom: 30px;
    transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
    font-family: 'Jaldi', sans-serif;
}

@media screen and (orientation:portrait) {
    .content {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 100%;
        height: 45vh;
    }

    .moray {
        position: relative;
        height: 35vh;
    }

    .gallery-title {
        color: #e3f0f2;
        text-align: center;
        font-family: 'Jaldi', sans-serif;
    }

    .gallery-link img {
        width: 70vw;
        justify-content: center;
        text-align: center;
        margin: 0 10px;
        border-radius: 10px;
        transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
    }
}

@media screen and (orientation:landscape) {
    .content {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 100%;
        height: 100vh;
    }

    .moray {
        position: relative;
        width: 100vw;
        height: 100vh;
    }

    .gallery-title {
        color: #e3f0f2;
        text-align: center;
        font-family: 'Jaldi', sans-serif;
        font-size: 50px;
    }

    .gallery-link img {
        width: 35vw;
        justify-content: center;
        text-align: center;
        margin: 0 10px;
        border-radius: 10px;
        transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
    }
}

.gallery-link img:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.gallery-link:hover h2 {
    color: #30b1ce;
    transform: scale(1.05);
    
}

.scroll-down {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    color: #fff;
    font-size: 1.2rem;
    cursor: pointer;
    font-family: 'Jaldi', sans-serif;
}

.arrow {
    width: 24px;
    height: 24px;
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
    transform: rotate(-45deg);
    margin: 8px auto 0;
    animation: bounce 2s infinite;
}

@keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0) rotate(-45deg);
    }

    40% {
        transform: translateY(-20px) rotate(-45deg);
    }

    60% {
        transform: translateY(-10px) rotate(-45deg);
    }
}