.dropdown-container {
    position: relative;
    display: inline-block;
    padding: 0;
    margin: 0;
    border-radius: 5px;
}

.dropdown-button {
    background-color: transparent;
    color: #e3f0f2;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    padding: 0px 16px;
    height: 5vh;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dropdown-button:hover {
    color: #003b61;
}

.dropdown-button::after {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    border-left: 2px solid #e3f0f2;
    border-bottom: 2px solid #e3f0f2;
    transform: rotate(-45deg);
    margin-left: 5px;
    position: relative;
    top: -2px;
}

.dropdown-button:hover::after {
    border-left: 1px solid #003b61;
    border-bottom: 1px solid #003b61;
}

.dropdown-content {
    display: none;
    position: absolute;
    right: 0;
    top: 100%;
    background-color: #30b1ce;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-radius: 8px;
}

.dropdown-content a {
    color: white;
    text-align: right;
    font-size: 20px;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content a:hover {
    color: #003b61;
}

.dropdown-container:hover .dropdown-content {
    display: block;
}